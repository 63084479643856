import * as React from "react"
import PropTypes from "prop-types"
import { alpha } from "@mui/material/styles"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import PreviewIcon from "@mui/icons-material/Preview"
import { visuallyHidden } from "@mui/utils"

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

const headCells = [
    {
        id: "ip",
        filter: true,
        disablePadding: false,
        label: "Ip",
    },
    {
        id: "body.deviceId",
        filter: true,
        disablePadding: false,
        label: "deviceId",
    },
    {
        id: "body.timeStamp",
        disablePadding: false,
        label: "Log Timestamp",
    },
    {
        id: "body.appInst",
        filter: true,
        disablePadding: false,
        label: "Application Instance",
    },
    {
        id: "body.type",
        filter: true,
        disablePadding: false,
        label: "Type",
    },
    {
        id: "body.msg",
        disablePadding: true,
        label: "Message",
    },
]

function EnhancedTableHead(props) {
    const { numSelected, rowCount, onSelectAllClick, order, orderBy, filters, filterValues} = props
    const createSortHandler = (property) => (event) => {props.onRequestSort(event, property)}
    const handleFilterChange = (filter, event) => {props.onFilterChange(filter, event.target.value)}

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={"TableCellFilter" + headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}>
                        {headCell.filter ? (
                            <span>
                                <InputLabel id={"InputLabelFilter" + headCell.id}>{headCell.id}</InputLabel>
                                <Select
                                    labelId={"InputLabelFilter" + headCell.id}
                                    id={"SelectFilter" + headCell.id}
                                    value={filterValues[headCell.id] || "Filter not set"}
                                    label={headCell.id}
                                    onChange={(event) => handleFilterChange(headCell.id, event)}>
                                    {filters[headCell.id].map((value) => {
                                        return <MenuItem value={value} key={"MenuItem" + value}>{value}</MenuItem>})}
                                </Select>
                            </span>) : null}
                    </TableCell>))}
            </TableRow>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all logs",
                        }}/>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number,
    rowCount: PropTypes.number,
    onSelectAllClick: PropTypes.func,
    onPreviewClick: PropTypes.func,
    onRequestSort: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    filterValues: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func,
}

const EnhancedTableToolbar = (props) => {
    const { numSelected, onPreviewClick } = props
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                bgcolor: (theme) => numSelected > 0 ? alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) : theme.palette.action.defaultOpacity,
            }}>
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div">
                    {numSelected} selected
                </Typography>) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div">
                    Logs
                </Typography>)}
            {numSelected > 0 ? (
                <Tooltip title="Preview">
                    <IconButton onClick={onPreviewClick}>
                        <PreviewIcon />
                    </IconButton>
                </Tooltip>) : null}
        </Toolbar>)
}
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number,
    onPreviewClick: PropTypes.func
}

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order: "asc",
            orderBy: "ip",
            selected: [],
            page: 0,
            rowsPerPage: 75,
            filters: {
            }
        }
    }

    handleRequestSort(event, property) {
        const isAsc = this.state.orderBy === property && this.state.order === "asc"
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property
        })
    }

    handleSelectAllClick(event) {
        var selected = []
        if (event.target.checked) {
            selected = this.props.logs.map((n) => n.name)
        }
        this.setState({
            selected
        })
    }

    handleClick(event, index) {
        const selectedIndex = this.state.selected.indexOf(index)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, index)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1))
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1)
            )
        }

        this.setState({
            selected: newSelected
        })
    }

    handleChangePage (event, newPage) {
        this.setPage(newPage)
    }

    handleChangeRowsPerPage (event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        })
    }

    handlePreviewClick () {
        this.props.onPreviewClick(this.props.logs.filter((log, index) => this.isSelected(index)))
    }

    handleFilterChange (filter, value) {
        var filters = Object.assign({}, this.state.filters)
        filters[filter] = value
        this.setState({
            filters
        })
    }

    isSelected (index) {
        return this.state.selected.indexOf(index) !== -1
    }

    getFilterValuesForKey (key) {
        return this.props.logs.reduce(function (acc, obj) {
            if (acc.indexOf(obj[key]) === -1) {
                acc.push(obj[key])
            }
            return acc
        }, [null])
    }

    render() {
        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows = this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.props.logs.length) : 0
        const filters = {
            ip: this.getFilterValuesForKey("ip"),
            "body.deviceId": this.getFilterValuesForKey("body.deviceId"),
            "body.appInst": this.getFilterValuesForKey("body.appInst"),
            "body.type": this.getFilterValuesForKey("body.type"),
        }
        const filteredLogs = this.props.logs.slice().filter((log) => {
            var match = true
            Object.keys(this.state.filters).forEach((key) => {
                var value = this.state.filters[key]
                if (value != null && value != log[key]) {
                    match = false
                }
            })
            return match
        })

        return (
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <EnhancedTableToolbar 
                        numSelected={this.state.selected.length} 
                        onPreviewClick={this.handlePreviewClick.bind(this)}/>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <EnhancedTableHead
                                numSelected={this.state.selected.length}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onSelectAllClick={this.handleSelectAllClick.bind(this)}
                                onRequestSort={this.handleRequestSort.bind(this)}
                                rowCount={filteredLogs.length}
                                filters={filters}
                                filterValues={this.state.filters}
                                onFilterChange={this.handleFilterChange.bind(this)}/>
                            <TableBody>
                                {filteredLogs.sort(getComparator(this.state.order, this.state.orderBy))
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((log, index) => {
                                        const isItemSelected = this.isSelected(index)
                                        const labelId = `enhanced-table-checkbox-${index}`
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => this.handleClick(event, index)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={"TableRow" + index}
                                                selected={isItemSelected}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}/>
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="none">{log.ip}</TableCell>
                                                <TableCell align="left">{log["body.deviceId"]}</TableCell>
                                                <TableCell align="left">{log.serverTimestamp}</TableCell>
                                                <TableCell align="left">{log["body.appInst"]}</TableCell>
                                                <TableCell align="left">{log["body.type"]}</TableCell>
                                                <TableCell align="left">{log["body.msg"]}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 33 * emptyRows,
                                        }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 750, 1000, 2500, 5000, 7500, 10000]}
                        component="div"
                        count={filteredLogs.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage.bind(this)}
                        onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}/>
                </Paper>
            </Box>
        )}
}
EnhancedTable.propTypes = {
    logs: PropTypes.array.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
}

export default EnhancedTable