/*
  Copyright 2022 Yotta Media Labs Ltd. All rights reserved.
*/

import Flat from "flat"

// Language: javascript
// Path: log-viewer/src/LogArchiveManager.js
const GetLogsServerPath = "/application-"
// Use this value for development testing
//const GetLogsServerPath = "https://remote-logger.hdplus.yottacloud.net/application-"

// Return logs as a javascript array of backend logs.
class LogArchiveManager {

    getLogsForDate(date = new Date(), fnSuccess, fnFailure) {

        var url = GetLogsServerPath + date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2) + ".log"
        var xhr = new XMLHttpRequest()
        xhr.open("GET", url)
        xhr.onload = function () {
            if (xhr.status === 200) {
                // The logs files are not in JSON format, so we need to parse them.
                const logStrings = xhr.responseText.split("\n")
                const logObjects = []

                // Each logString is a JSON object: {message: {...}, level: "info"}
                logStrings.forEach(function (logString) {
                    try {
                        if (logString) {

                            // Each message object:
                            // {
                            //      "timestamp":"2022-04-08T04:38:32.843Z", // Server timestamp
                            //      "timestampMs":1649392712843, // Server timestamp in milliseconds
                            //      "ip":"::ffff:127.0.0.1", // Ignore this field
                            //      "headers": [] // client request headers (extract "x-client-id")
                            //      "body": [{
                            //          {
                            //              "appInst": 1649347141,      // Unique id to the application run time
                            //              "deviceId": "350000085768", // HD+ number - unique to the receiver
                            //              "msg":"Channels init success", // Trace message
                            //              "timeStamp":"2022-04-07T22:28:27.000Z", // Client timestamp
                            //              "type":"I"  // Timber type (V, D, I, W, E)
                            //           },
                            //      ...
                            //      ] // client request body
                            // }

                            const messageObject = JSON.parse(logString).message

                            const serverTimestamp = messageObject.timestamp
                            const serverTimestampMs = messageObject.timestampMs
                            const ip = messageObject.headers["x-client-ip"] ? messageObject.headers["x-client-ip"] : "Unknown"
                            const logBody = Array.isArray(messageObject.body) ? messageObject.body : []

                            logBody.forEach((body) => {
                                logObjects.push(Flat.flatten({
                                    serverTimestamp,
                                    serverTimestampMs,
                                    ip,
                                    body
                                }))
                            })
                        }
                    } catch(e) {
                        console.log("Failed to parse log: " + logString)
                    }
                })
                fnSuccess(logObjects.sort((a, b) => {
                    return new Date(a["body.timeStamp"]).getTime() < new Date(b["body.timeStamp"]).getTime()
                }))
            } else {
                fnFailure(xhr.status)
            }
        }
        xhr.send()
    }

    // TODO: Connect a WSS to the log archive server to get real-time logs.

}

const logArchiveManager = new LogArchiveManager()

export default logArchiveManager