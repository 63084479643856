/*
  Copyright 2022 Yotta Media Labs Ltd. All rights reserved.
*/
import "./App.css"
import React from "react"
import {Button, Grid, TextField, Typography} from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import LogTable from "./LogTable"
import LogArchiveManager from "./LogArchiveManager"

class App extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            status: "Loading...",
            logDate: new Date(),
            logs: [],
            previewLogs: []
        }
    }

    componentDidMount () {
        this.getLogs()
    }

    getLogs () {
        LogArchiveManager.getLogsForDate(this.state.logDate, (logs) => {
            this.setState({
                status: "Loaded",
                logs: logs,
                previewLogs: []
            })
        }, (status) => {
            this.setState({
                status: "Failed to get logs, statusCode: " + status
            })
        })
    }

    handlePreviewClick (previewLogs) {
        this.setState({
            previewLogs
        })
    }

    handleRefeshClick () {
        this.getLogs()
    }

    render () {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="App">
                    <Typography variant="h4" sx={{mt:"8px"}}>Log Viewer</Typography>
                    <Grid container spacing={2} sx={{p:"8px"}}>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Select a date"
                                value={this.state.logDate}
                                onChange={(newValue) => {
                                    this.setState({logDate: newValue},
                                        ()=> {this.getLogs()})}}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" sx={{m: "4px"}} onClick={this.handleRefeshClick.bind(this)}>Refresh</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{m: "4px", p: "6px"}}>{"Status: " + this.state.status}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LogTable logs={this.state.logs} onPreviewClick={this.handlePreviewClick.bind(this)}/>
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.previewLogs.map((log, index) => {return <pre className="AppTextFixedWidth" key={"TypographyLog"+ index}>{JSON.stringify(log, undefined, 4)}</pre>})}
                        </Grid>
                    </Grid>
                </div>
            </LocalizationProvider>
        )
    }
}

export default App
